.container {
    width: 100%;
    max-width: 1920px;
    margin: auto;
}

@media screen and (width <=1000px) {
    .containerChild {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}