.content {
    width: 100%;
    margin: auto;
    border-radius: 4px;
    background-color: #F7F7F7;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.desc {
    display: flex;
    flex-direction: column;
    justify-content: center;


    padding: 5%;

}

.desc h3,
.desc h5 {
    font-family: "Inter";
    text-align: left;
}

.desc h3 {
    font-weight: bold;
    font-size: 57px;
    letter-spacing: 2px;
    width: 80%;
    margin-bottom: 20px;
}

.desc h5 {
    font-weight: semibold;
    font-size: 24px;
}


.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 31px;
}

.content_form {
    width: 100%;
    padding: 50px;
}

.form {
    background-color: white;
    padding: 50px;
    width: 100%;
    border-radius: 6px;
}

.form>input,
.form>textarea,
.form>button,
.form>label {
    width: 100%;
    margin-bottom: 15px;
}

.form>input::placeholder,
.form>textarea::placeholder {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    color: #BDBDBD;
}

.form>input:focus,
.form>textarea:focus {
    outline: none;
    border: 1px solid #AA0318;
}



.form>label {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 500;
}

.form>input {
    height: 63px;
    padding-left: 15px;
}

.form>textarea {
    height: 117px;
    padding: 15px;
    resize: none;
}

.form>button {
    height: 69px;
    color: white;
    font-family: "Gilroy";
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    border: none;
    cursor: pointer;
}

.form>button:active {
    transform: scale(.97);
}


@media screen and (width <=1200px) {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .desc h3 {
        width: 100%;
    }
}

@media screen and (width <=750px) {
    .desc h3 {
        font-size: 37px;
    }

    .desc h5 {
        font-size: 18px;
    }

    .content_form {
        padding: 10px;
    }

    .form {
        padding: 15px;
    }

}

@media screen and (width <=660px) {
    .desc h3 {
        font-size: 33px;
    }

    .desc h5 {
        font-size: 15px;
    }
}

@media screen and (width <=550px) {
    .desc h3 {
        font-size: 27px;
    }

    .desc h5 {
        font-size: 13px;
    }

    .form>button {
        font-size: 18px;
    }
}

@media screen and (width <=320px) {
    .desc h3 {
        font-size: 20px;
    }

    .desc h5 {
        font-size: 10px;
    }

    .form>button {
        font-size: 15px;
    }
}