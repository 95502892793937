.home_image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}


.image {
    height: 100%;
    width: min(21vw, 419px);
    object-fit: cover;
}

@media screen and (width >=1400px) {
    .home_image {
        margin-right: 90px;
    }
}


@media screen and (width <=1000px) {
    .image {
        /* width: min(80vw, 419px); */
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }
}

@media screen and (width <=400px) {
    .image {
        /* width: min(80vw, 419px); */
        width: 100%;
        height: 100vw;
        object-fit: cover;
    }
}