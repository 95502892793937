.btn_parent {
    display: flex;
    flex-direction: row;
}

.btn {
    width: min(14vw, 282.73px);
    height: min(4vw, 85.56px);


    display: grid;
    grid-template-columns: 1fr 2fr;
    place-items: center;

    border-radius: 8px;
    margin-right: .5vw;
    background-color: black;
    cursor: pointer;
}

.btn:active {
    transform: scale(0.95);
}

.btn_icon>img {
    width: min(47px, 2vw);
    height: min(57px, 2.5vw);
}

.btn_text {
    color: white;
    width: 100%;
    padding: 7px;
}

.btn_text>:first-child {
    font-family: 'Inter';
    font-size: min(.9vw, 19px);
}

.btn_text>:nth-child(2) {
    font-family: 'Inter';
    font-size: min(1.2vw, 22px);
}

@media screen and (width<=1000px) {
    .btn {
        width: 160px;
        height: 40px;
        border-radius: 3px;
    }

    .btn_icon>img {
        width: 20px;
        height: 20px;
    }

    .btn_text>:first-child {
        font-size: 10px;
    }

    .btn_text>:nth-child(2) {
        font-size: 15px;
    }
}

@media screen and (width<=600px) {
    .btn_parent {
        flex-direction: column;
        gap: 30px;
    }

    .btn {
        width: 130px;
        height: 40px;
        border-radius: 3px;
    }

    .btn_icon>img {
        width: 20px;
        height: 20px;
    }

    .btn_text>:first-child {
        font-size: 6px;
    }

    .btn_text>:nth-child(2) {
        font-size: 11px;
    }
}

@media screen and (width<=300px) {
    .btn {
        width: 45vw;
        height: 30px;
        border-radius: 3px;
    }

    .btn_icon>img {
        width: 7vw;
        height: 7vw;
    }

    .btn_text>:first-child {
        font-size: calc(38vw / 16);
    }

    .btn_text>:nth-child(2) {
        font-size: calc(38vw / 12);
    }
}