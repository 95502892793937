.smoothie {
    width: min(400px, calc((100vw / 4) - 40px));
    height: min(322px, calc((100vw / 4) - 80px));
    border: 2px solid #F2F2F2;
    box-shadow: 0px -11px 40px #00000014;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: grid;
    grid-template-rows: 3fr 1fr 3fr;
    justify-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

}

.smoothie>:nth-child(1) {
    height: 85px;
}

.smoothie>:nth-child(2) {
    font-family: "inter";
    font-weight: bold;
    font-size: min(24px, calc(((100vw / 4) - 40px) / 16.5));
}

.smoothie>:nth-child(3) {
    font-family: "inter";
    font-size: min(16px, calc(((100vw / 4) - 40px) / 22.5));
    text-align: center;
    margin: 0 10px;
    line-height: 1.5;
}

@media screen and (width <=1200px) {
    .smoothie {
        width: min(400px, calc((100vw / 2) - 40px));
        height: min(322px, calc((100vw / 2) - 80px));
    }

    .smoothie>:nth-child(2) {
        font-size: min(24px, calc(((100vw / 2) - 40px) / 16.5));
    }

    .smoothie>:nth-child(3) {
        font-size: min(16px, calc(((100vw / 2) - 40px) / 22.5));
    }
}

@media screen and (width <=1200px) {
    .smoothie {
        width: min(400px, calc((100vw / 1) - 40px));
        height: min(322px, calc((100vw / 1) - 80px));
    }

    .smoothie>:nth-child(2) {
        font-size: min(24px, calc(((100vw / 1) - 40px) / 16.5));
    }

    .smoothie>:nth-child(3) {
        font-size: min(16px, calc(((100vw / 1) - 40px) / 22.5));
    }
}

@media screen and (width <=400px) {
    .smoothie>:nth-child(1) {
        height: 55px;
    }
}

@media screen and (width <=300px) {
    .smoothie>:nth-child(1) {
        height: 35px;
    }
}