.rewards {
    padding-top: 1px;
}

.content {
    width: fit-content;
    margin: auto;
    margin-top: 90px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}

@media screen and (width <=1400px) {
    .content {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}

@media screen and (width <=1000px) {
    .content {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}