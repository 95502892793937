.our_smoothies_content {
    width: fit-content;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    gap: 30px;
}

@media screen and (width <=1200px) {
    .our_smoothies_content {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}

@media screen and (width <=900px) {
    .our_smoothies_content {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
}