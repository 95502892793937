.content {
    width: 100%;
    padding: 100px 100px 0 100px;


    display: flex;
    justify-content: space-between;
}

.left_side>p {
    margin-top: 20px;
    font-size: 24px;
    font-family: "Gilroy";
    font-weight: semibold;
}

.location {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.location>img {
    width: 29.59px;
    height: 29.19px;
}

.location>span {
    font-size: 19px;
    font-family: "Gilroy";
    font-weight: semibold;
}

.link {
    margin: 40px 100px;
    display: flex;
    justify-content: space-between;
}




@media screen and (width<=1000px) {
    .content {
        padding: 10px;
    }

    .left_side>img {
        width: 25%;
    }

    .left_side>p {
        font-size: 14px;
    }

    .location>img {
        width: 19.59px;
        height: 19.19px;
    }

    .location>span {
        font-size: 13px;
        font-family: "Gilroy";
        font-weight: semibold;
    }
    .link{
        margin: 40px 0px;
    }

}

@media screen and (width <= 600px) {
    .link{
        font-size: 12px;
    }
}
@media screen and (width <= 480px) {
    .link{
        flex-direction: column;
        gap: 10px;
    }
}