@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&family=Ubuntu:wght@300&display=swap');

:root {

  /* color */
  --btn-color: #AA0119;

  /* shadow */
  --btn-shadow: 0px -10px 40px #E6040429;
  --btn-radius: 8px;


}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'roboto';
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background: #AA0119 0% 0% no-repeat padding-box;
  box-shadow: 0px -10px 40px #E6040429;
  border-radius: 8px;
}

.mgtop36 {
  margin-top: 36px;
}

.posRelative {
  position: relative;
}