.text_title {
    width: 100%;
    margin-top: 150px;
    margin-bottom: 50px;

    text-align: center;
    font-size: min(60px, 3vw);
    font-weight: bold;
    font-family: "inter";
    text-transform: capitalize;
    opacity: .87;

}

@media screen and (width<=1400px) {
    .text_title {
        font-size: calc(100vw / 21);
    }
}