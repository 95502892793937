.home_section {
    width: 100%;
    padding-top: 95px;
}

.content {
    display: flex;
    justify-content: space-between;
}

@media screen and (width <=1000px) {
    .content {
        flex-direction: column;
        align-items: center;
    }
}