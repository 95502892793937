.topbar {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
}

.content {
    display: flex;
    align-items: center;
    height: 95px;
    border-radius: 14px;
    background-color: white;
    box-shadow: -1px -11px 40px #0000001f;

}

.content_child {
    display: flex;
    align-items: center;
    height: 96px;
}

.logo {
    width: 4vw;
    max-width: 79px;
    height: 4vw;
    max-height: 79px;
    margin-right: 32px;
    object-fit: cover;
}

.menu_icon {
    cursor: pointer;
    width: 59px;
    height: 59px;
}

.nav_containre {
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
}

@media screen and (width<=1000px) {
    .logo {
        width: 59px;
        height: 59px;
        margin-right: 30px;
        object-fit: cover;
    }
}