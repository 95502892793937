@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Roboto:wght@100;300;400;500;700&family=Ubuntu:wght@300&display=swap');


.home_text>:nth-child(1) {
    font-family: 'inter';
    font-weight: bold;
    /* font-size: min(3vw, 72px); */
    font-size: min(5vw, 72px);
    padding-top: min(150px, 7.8vw);
    text-transform: capitalize;
}


.home_text>:nth-child(2) {
    font-family: 'Sofia Pro';
    font-weight: bold;
    font-size: min(24px, 1vw);
    padding-top: min(15px, .7vw);
    color: black;
}

.home_text>:nth-child(3) {
    padding-top: min(4vw, 84px);
}


@media screen and (1200px>=width>1000px) {
    .home_text>:nth-child(1) {
        font-size: max(3vw, 63px);

    }
}

@media screen and (width<=1000px) {
    .home_text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_text>:nth-child(1) {
        font-size: calc(100vw/13);
        padding-top: max(50px, 7.8vw);
    }

    .home_text>:nth-child(2) {
        font-size: calc(100vw/40);
        padding-top: max(4px, .7vw);
    }
}