.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    max-height: 650px;
    padding: 0 50px 0 50px;
    position: relative;
}

.close_btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.close_btn:hover {
    background-color: black;
    color: white;
    border-radius: 50%;
}



.content>img {
    width: 150px;
    margin-bottom: 50px;
    margin-top: 50px;
}




.footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 50px;
}

.footer>p {
    margin-top: 5px;
    font-size: 15px;
    font-family: "Gilroy";
    font-weight: semibold;
}

.location {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.location>img {
    width: 24.59px;
    height: 24.19px;
}

.location>span {
    font-size: 13px;
    font-family: "Gilroy";
    font-weight: semibold;
}

.link {
    margin-top: 30px;
    display: flex;
    gap: 35px;
    margin-bottom: 50px;
}

.link a:hover {
    color: navy;
}


@media screen and (width<=750px) {

    .content>img {
        width: 100px;
        margin-bottom: 50px;
    }
}