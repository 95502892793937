.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    z-index: 2;
}

.menu>a {
    margin-right: 32px;
    line-height: '96px';
    cursor: pointer;
}

.downloadBtn {
    box-shadow: .4vw;
    border-radius: var(--btn-radius);
    height: min(3vw, 69px);
    /* height: max(3vw, 30px); */
    width: min(11vw, 224px);
    /* width: max(11vw, 140px); */

}

.downloadBtn:hover {
    background-color: var(--btn-color) !important;
}

.downloadBtn:active {
    background-color: var(--btn-color) !important;
    transform: scale(.9);

}

.nav_link {
    font-size: min(1.1vw, 18px);
    line-height: 50px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Gilroy-bold';
    text-align: center;
}

.nav_btn {
    font-size: min(.8vw, 16px) !important;
    font-weight: 600 !important;
}

@media screen and (width <=1000px) {
    .navbar {
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0 0 14px 14px;
        margin-top: 80px;
        padding-bottom: 10px;
        height: 0px;
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
        transition: height 1s;
    }



    .menu {
        display: flex;
        flex-direction: column;
    }

    .menu>a {
        margin-right: 0px;
        line-height: '96px';
    }

    .nav_link {
        font-size: max(1vw, 17px);
    }

    .nav_btn {
        font-size: max(11px, 1vw) !important;
    }

    .downloadBtn {
        height: max(3vw, 40px);
        width: max(11vw, 140px);
    }
}