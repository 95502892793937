.content {
    display: flex;

    background: rgba(249, 247, 253, .68);
    border-radius: 14px;
}

.content img {
    width: min(50vw, 929px);
}

.desc {
    padding-left: 80px;
    padding-top: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
}

.desc h3 {
    margin-bottom: 3%;
    font-size: min(50px, 3vw);
    letter-spacing: 3.3px;
    font-weight: bold;
    opacity: 87%;
    color: black;
}

.desc p {
    margin-bottom: 3%;
    font-size: min(24px, 1.2vw);
    font-family: "Inter";
    line-height: 1.7;
}

.link {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}



.link span {
    font-size: min(16px, 1.1vw);
    font-family: "Inter";
    margin-right: 10px;
}

@media screen and (width <=700px) {
    .content {
        flex-direction: column;
        align-items: center;
    }

    .desc {
        order: 1;
        padding: 5%;
    }

    .content>:first-child {
        order: 2;
        padding: 5%;
    }

    .content img {
        width: 100%;
        border-radius: 8px;
    }

    .desc p,
    .link span {
        font-size: 12px;
    }

    .desc h3 {
        font-size: 20px;
    }
}