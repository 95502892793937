.reward {
    width: min(590px, calc((100vw / 3) - 40px));
    height: min(443px, calc((100vw / 3) - 80px));
    border: 2px solid #F2F2F2;
    border-radius: 4px;
    box-shadow: 0px -11px 40px #00000014;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    padding: 8%;

}

.reward>:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reward p {
    font-size: 24px;
    font-family: "roboto";
    font-weight: 400;
    text-align: center;
    line-height: 1.6;
}

@media screen and (width <=1400px) {
    .reward {
        width: min(590px, calc((100vw / 2) - 40px));
        height: min(443px, calc((100vw / 2) - 80px));
    }
}

@media screen and (width <=1000px) {
    .reward {
        width: max(590px, calc((100vw / 2) - 40px));
        height: max(443px, calc((100vw / 2) - 80px));
    }
}

@media screen and (width <=620px) {
    .reward {
        width: calc(100vw - 20px);
        height: calc(100vw - 60px);
    }
}

@media screen and (width <=440px) {
    .reward p {
        font-size: 22px;
        line-height: 1.3;
    }

    .reward img {
        width: 80%;
    }


}

@media screen and (width <=340px) {
    .reward p {
        font-size: 15px;
        line-height: 1.3;
    }

    .reward img {
        width: 60%;
    }
}